* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  position: relative;
  font-size: 14px;
  color: #111111;
  letter-spacing: 0.018em;
  line-height: 1.45em;
}

a {
  color: rgb(240,121,55);
  text-decoration: none;
  transition: color .25s ease;

  svg path.move {
    stroke: rgb(202, 105, 50);
  }

  &:hover {
    color: rgb(202, 105, 50);

    svg path.move {
      stroke: rgb(202, 105, 50);
    }
  }
}

.container {
  width: 1000px;
  margin: auto;

  @include respond-down(M){
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.page-content {
  padding: 34px 56px;

  @include respond-down(M){
    padding: 20px 0;
  }
}

.columns {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;

  .column-two {
    width: 66.666%;
    padding: 0 12px;

    @include respond-down(M){
      width: auto;
      flex: 1 1 60%;
    }
  }

  .column-one {
    width: 33.333%;
    padding: 0 12px;

    &.ui-sortable-helper {
    //  transform: scale(0.5);
    }

    &.sidebar-product {
      @include respond-down(M){
        width: 270px;
      }
      @include respond-down(XS){
        width: 100%;
      }
    }

    &.product-one {
      @include respond-down(M){
        width: 50%;
      }
      @include respond-down(XS){
        width: 100%;
      }
    }
  }
}

.change-language {
  display: flex;
  margin: 0 -5px;

  .item {
    padding: 5px;
    height: 32px;

    @include respond-down(M){
    }

    svg {
      width: 32px;
      height: 22px;
      border: 2px solid rgba(255,255,255,0.3);
      border-radius: 2px;
      transition: border .25s ease;
    }

    &:hover {
      svg {
        border: 2px solid rgba(255,255,255,0.8);
      }
    }
  }
}

.block-header {
  background: linear-gradient(to bottom, rgb(57,57,59) 0%, rgb(64,64,66) 100%);
  margin-bottom: 8px;

  .block-header-content {
    height: 140px;
    padding-bottom: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include respond-down(M){
      height: 100px;
      padding-bottom: 0;
      align-items: center;
    }
    @include respond-down(S){
      flex-direction: column;
      height: auto;
    }

    .logo {
      @include respond-down(S){
        padding: 10px 0;
      }

      img {
        height: 70px;
        margin-bottom: -10px;

        @include respond-down(M){
          height: 60px;
          margin-bottom: 0;
        }
        @include respond-down(S){
          height: 48px;
        }
      }
    }

    .search-box {
      position: relative;
      width: 290px;

      @include respond-down(S){
        margin-bottom: 20px;
      }
      @include respond-down(XS){
        width: 100%;
      }

      .form-control {
        padding-right: 58px;
      }

      .search-button {
        position: absolute;
        display: flex;
        right: 1px;
        top: 1px;
        bottom: 1px;
        width: 52px;
        background: rgb(240,121,55);
        align-items: center;
        justify-content: space-around;
        border: none;
        cursor: pointer;
        transition: background .25s ease;

        svg {
          height: 18px;

          path {
            fill: #ffffff;
          }
        }

        &:hover {
          background: rgb(202, 105, 50);
        }
      }
    }
  }
}

.block-menu {
  position: relative;
  z-index: 40;
  background: linear-gradient(to bottom, rgb(216,109,49) 0%, rgb(240,121,55) 100%);

  .mobile-opener {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 30px;
    height: 28px;
    flex-direction: column;
    position: absolute;
    left: 24px;
    top: 10px;
    cursor: pointer;

    .line {
      height: 4px;
      background: #FFF;
      width: 100%;
    }

    @include respond-down(S){
      display: flex;
    }
  }

  .block-menu-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-down(S){
      overflow: hidden;
      height: 48px;
      padding-top: 48px;
      align-items: flex-start;
      transition: height 0.35s ease;
    }

    &.opened {
      @include respond-down(S){
        height: 256px;
      }
    }

    .menu-items {
      margin: 0 -15px;
      display: flex;

      @include respond-down(M){
        margin: 0 -10px;
      }
      @include respond-down(S){
        flex-direction: column;
        margin: 0 -24px;
        width: auto;
        flex: 1 1 100%;
        background: rgba(0,0,0,0.1);
        padding: 8px 0;
      }

      .item-wrapper {
        position: relative;

        .drop-down {
          overflow: hidden;
          transition: max-height 0.25s ease;
          position: absolute;
          max-height: 0;
          left: 0;
          top: 100%;

          @include respond-down(S){
            display: none;
          }

          .item {
            color: transparent;
            transition: color 0.25s ease;
            background: rgb(240,121,55);
            height: 35px;
            border-top: 1px solid rgba(0,0,0,0.1);
          }
        }

        &:hover {
          .drop-down {
            max-height: 35px * 3;

            .item {
              color: #FFFFFF;

              &:hover {
                color: rgb(75,67,65);
              }
            }
          }
        }
      }

      .item {
        height: 60px;
        padding: 0 15px;
        text-transform: uppercase;
        cursor: pointer;
        transition: color .25s ease;
        color: #FFFFFF;
        font-weight: bold;
        display: flex;
        align-items: center;
        font-size: 14px;
        letter-spacing: 0.0em;

        @include respond-down(M){
          height: 48px;
          font-size: 13px;
          padding: 0 10px;
        }
        @include respond-down(S){
          height: 32px;
          justify-content: space-around;
        }

        &:hover, &.current {
          color: rgb(75,67,65);
        }
      }
    }

    .change-language {
      @include respond-down(S){
        position: absolute;
        right: 24px;
        top: 8px;
      }
    }
  }
}

.block-content {
  position: relative;
  z-index: 20;
  box-shadow: 0 3px 15px rgba(0,0,0,0.12);

  @include respond-down(M){
    box-shadow: none;
    overflow: hidden;
  }

  .page-content {
    margin-bottom: 80px;

    @include respond-down(M){
      margin-bottom: 0;
    }
  }
}

.block-footer {
  @include respond-down(M){
    margin-left: -24px;
    margin-right: -24px;
    box-shadow: none;
  }

  .final-line {
    background: linear-gradient(to bottom, rgb(216,109,49) 0%, rgb(240,121,55) 100%);
    height: 14px;
    margin-bottom: 8px;
  }

  .footer-data {
    background: linear-gradient(to bottom, rgb(57,57,59) 0%, rgb(64,64,66) 100%);
    min-height: 150px;

    @include respond-down(M){
      padding: 0 24px;
    }

    .page-content {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .info {
      line-height: 1.70em;
      font-size: 12px;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: bold;

      &.head {
        color: rgb(240,121,55);
      }

      .link {
        color: #ffffff;

        &:hover {
          color: rgb(202, 105, 50);
        }
      }
    }

    .columns {
      @include respond-down(S){
        flex-direction: column;
      }
    }

    .column-one {
      @include respond-down(M){
        width: 36.333%;
      }
      @include respond-down(S){
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .separated {
      display: flex;
      justify-content: space-between;

      @include respond-down(M){
        width: 39.333%;
      }
      @include respond-down(S){
        width: 100%;
        flex-direction: column;
      }

      .catalog-links {
        @include respond-down(S){
          margin-bottom: 20px;
        }
      }
    }

    .logo-column {
      padding-left: 80px;

      img {
        height: 130px;
      }

      @include respond-down(M){
        width: 24.333%;
      }
      @include respond-down(S){
        width: 100%;
        padding-left: 0;
      }
    }
  }

}

.navigation {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .to-left svg {
    transform: rotate(180deg);
    margin-right: 2px;
    margin-top: -2px;
  }
  .to-right svg {
    margin-left: 2px;
    margin-top: -2px;
  }

  svg {
    transition: transform 0.35s ease;
    height: 10px;
  }

  .link {
    display: flex;
    align-items: center;

    &:hover {
      &.to-left svg {
        transform: rotate(180deg) translateX(5px);
      }
      &.to-right svg {
        transform: translateX(5px);
      }
    }
  }

  .arrows {
    display: flex;
    justify-content: space-between;
    width: 220px;

    @include respond-down(XS){
      width: 180px;
    }
  }
}

.not-found {
  text-align: center;
  font-size: 20px;
  color: #999;
  font-weight: bold;
}

.contact-form {
  max-width: 450px;
}
