body.ready-for-edit [data-inline-edit], body.ready-for-edit [data-inline-editor] {
  display: inline-block;
  position: relative;
  white-space: pre-line;
}
body.ready-for-edit [data-inline-edit]:after, body.ready-for-edit [data-inline-editor]:after {
  position: absolute;
  left:   -6px;
  right:  -6px;
  top:    -6px;
  bottom: -6px;
  content: '';
  border-radius: 3px;
  cursor: text;
  border: 1px dashed rgba(0,100,0,0.3);
  background-color: rgba(0,250,0,0.3);
}

[data-ie-start], [data-ie-save] {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

[data-ie-save] {
  display: none;
}

body.ready-for-edit {
  [data-ie-start] {
    display: none;
  }
  [data-ie-save] {
    display: block;
  }
}


.mce-container.mce-menubar {
  display: none;
}
.mce-flow-layout-item {
  margin: 0 2px;
}
.mce-tinymce-inline.mce-container.mce-panel {
  border-radius: 5px;
}
.mce-tinymce-inline.mce-container.mce-panel > .mce-container-body.mce-abs-layout {
  border-radius: 5px;
}
.mce-tinymce-inline.mce-container {
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  background: #fff;
}
.mce-btn {
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

