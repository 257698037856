.h1,.h2,.h3,.h4 {
  text-transform: uppercase;
  font-weight: bold;
  color: #222222;
  margin-bottom: 1em;
  line-height: 1.35em;
}

.h1 {
  font-size: 20px;

  @include respond-down(M){
    font-size: 18px;
  }
}

.h2 {
  font-size: 18px;

  @include respond-down(M){
    font-size: 16px;
  }
}

.h3 {
  font-size: 16px;

  @include respond-down(M){
    font-size: 15px;
  }
}

.h4 {
  font-size: 16px;

  @include respond-down(M){
    font-size: 15px;
  }
}

.page-text p {
  margin-bottom: 0.95em;
}

.button {
  user-select: none;
  background: rgb(240,121,55); // linear-gradient(to bottom, rgb(216,109,49) 0%, rgb(240,121,55) 100%);
  border: none;
  align-items: center;
  justify-content: space-around;
  display: flex;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 36px;
  cursor: pointer;
  font-size: 12px;
  transition: background 0.25s ease, color 0.25s ease, box-shadow 0.35s ease;
  border-radius: 1px;
  min-width: 130px;
  text-align: center;
  padding: 2px 7px;

  @include respond-down(M){
    min-width: 100px;
  }

  &.button-small {
    font-size: 11px;
    min-height: 28px;
  }

  &:hover {
    color: #FFFFFF;
    background: rgb(216,109,49);
    box-shadow: 0 0 0 5px rgba(240,121,55,0.3);
  }
  &:focus, &:active {
    box-shadow: 0 0 0 5px rgba(240,121,55,0.8);
  }

  &.button-blue {
    background: rgb(40,191,240);

    &:hover {
      color: #FFFFFF;
      background: rgb(35,173,216);
      box-shadow: 0 0 0 5px rgba(35,173,216,0.25);
    }
    &:focus, &:active {
      box-shadow: 0 0 0 5px rgba(35,173,216,0.4);
    }
  }

  &.button-danger {
    background: rgb(240, 41, 45);

    &:hover {
      color: #FFFFFF;
      background: rgb(209, 40, 44);
      box-shadow: 0 0 0 5px rgba(209, 40, 44, 0.25);
    }
    &:focus, &:active {
      box-shadow: 0 0 0 5px rgba(209, 40, 44, 0.4);
    }
  }

  &.disabled {
    cursor: default;
    background: #ada9ad !important;
    opacity: 0.7;
    box-shadow: none;
  }

  &.button-gray {
    background: rgb(62,62,62);

    &:hover {
      background: rgb(0,0,0);
      color: #FFF;
      //color: rgb(216,109,49);
    }
  }
}
