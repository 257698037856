.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }

  .block-header {
    display: none;
  }
  .block-menu {
    display: none;
  }
  .block-footer {
    display: none;
  }

  .product-page {
    .actions-list {
      display: none !important;
    }
    .navigation {
      display: none;
    }
    .pre-title {
      margin-bottom: 20px;
      font-size: 26px;
    }
    .container {
      box-shadow: none;
      max-width: 800px;
    }
    .columns {
      flex-direction: column;
      margin: 0;

      .product-column {
        padding: 0 !important;
        flex: 0 0 100%;
        width: 100%;
      }

      .product-gallery {
        order: 2;

        .large-version-wrapper {
          max-width: 400px;
        }

        .thumbs-image {
          display: none;
        }
      }
    }
  }
}
