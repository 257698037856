.product-column {
  width: 50%;
  padding: 0 12px;

  @include respond-down(S){
    width: 100%;
  }

  &.product-gallery {
    padding-right: 28px;

    @include respond-down(M){
      padding-right: 12px;
    }
    @include respond-down(S){
      margin-bottom: 20px;
    }

    .large-version-wrapper {
      position: relative;
      margin-bottom: 8px;
      min-height: 190px;
      background: #EEE;

      .img {
        display: block;
        width: 100%;
      }

      .large-version {
        position: absolute;
        overflow: hidden;
        width: 120px;
        height: 120px;
        border-radius: 180px;
        box-shadow: 0 0 0 2px rgba(240,121,55,0.5);
        background: rgba(255,255,255,0.85);
        margin: -60px 0 0 -60px;
        transform: scale(1.4);
        opacity: 0;
        transition: opacity 0.18s ease, transform 0.18s ease;

        .img {
          max-width: none;
          position: relative;
          width: 100%;
        }
      }

      &.overflow {
        overflow: hidden;
      }

      &.active {
        .large-version {
          transform: scale(2.5);
          opacity: 1;
        }
      }
    }

    .edit-images {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        width: 204px;
        font-size: 10px;

        input {
          cursor: pointer;
          z-index: 10;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          font-size: 100px;
        }
      }
    }

    .progress {
      margin-bottom: 6px;
      height: 3px;
      width: 0%;
      border-radius: 3px;
      background: rgb(40,191,240);
    }

    .thumbs-image {
      display: flex;
      margin-left: -4px;
      margin-right: -4px;
      flex-wrap: wrap;

      .thumb-wrapper {
        flex: 0 0 25%;
        margin: 0;
        padding: 4px;

        @include respond-down(XS){
          flex: 0 0 33.333%;
        }

        .button {
          min-width: 98px;
        }

        .thumb {
          background: #eee no-repeat center center;
          background-size: cover;
          cursor: pointer;
          padding-bottom: 80%;
          transition: box-shadow 0.35s ease;
          box-shadow: inset 0 0 0 5px rgba(240,121,55,0.0);

          &.current {
            box-shadow: inset 0 0 0 5px rgba(240,121,55,0.9);
          }

          &:hover {
            box-shadow: inset 0 0 0 5px rgba(240,121,55,0.6);
          }
        }
      }
    }
  }

  &.product-description {
    padding-left: 26px;

    @include respond-down(M){
      padding-left: 12px;
    }

    .params-list {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;

      .line {
        .name {
          min-width: 140px;
        }
      }
    }

    .product-edit-form {
      margin-bottom: 40px;
    }

    .actions-list {
      display: flex;
      justify-content: space-between;

      @include respond-down(XS){
        flex-direction: column;
      }

      .button {
        width: 32%;
        height: 46px;

        @include respond-down(XS){
          width: 100%;
          margin-bottom: 12px;
        }
      }
    }
  }
}

.g-recaptcha {
  margin-bottom: 20px;
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  align-items: center;
  justify-content: space-around;
  display: none;

  .modal-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
  }

  .modal-body {
    width: 420px;
    background: #FFF;
    padding: 26px 28px;
    position: relative;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    transition: transform 0.35s ease, opacity 0.35s ease;
    opacity: 0;
    transform: scale(1.2);

    .close {
      position: absolute;
      right: 28px;
      top: 28px;
      cursor: pointer;
      width: 18px;
      height: 18px;

      path {
        transition: fill 0.25s ease;
      }

      &:hover {
        path {
          fill: rgb(202, 105, 50);
        }
      }
    }

    .h3 {
      text-transform: uppercase;
      margin-bottom: 1em;
    }
  }
}

.modal-active {
  .modal {
    display: flex;
  }
}

.modal-show {
  overflow: hidden;

  .modal {
    .modal-body {
      opacity: 1;
      transform: scale(1);
    }
  }
}
