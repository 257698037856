form {
  &.login-form {
    max-width: 500px;
  }

  .form-thanks {
    font-size: 18px;
    font-weight: bold;

  }
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea, select {
  width: 100%;
  background: linear-gradient(to bottom, rgb(229,229,229) 0%, rgb(252,252,252) 100%);
  height: 30px;
  padding: 0 7px;
  border: 1px solid rgb(231,231,231);
  font-size: 14px;
  line-height: 30px;
  border-radius: 1px;
  box-shadow: 0 0 0 5px rgba(240,121,55,0.0);
  transition: box-shadow .25s ease;

  &:focus, &:active {
    box-shadow: 0 0 0 5px rgba(240,121,55,0.5);
  }
}

textarea {
  resize: vertical;
  height: auto;
  min-height: 122px;
  line-height: 20px;
}

.checkbox-wrapper {
  .regular-checkbox {
    display: none;

    & + label {
      cursor: pointer;
      display: flex;
      align-items: center;
      user-select: none;

      .checkbox {
        position: relative;
        background: linear-gradient(to bottom, rgb(229,229,229) 0%, rgb(252,252,252) 100%);
        height: 24px;
        border: 1px solid rgb(231,231,231);
        margin-right: 8px;
        flex: 0 0 24px;
      }

      .text {
        font-size: 11px;
        line-height: 1.4em;
      }
    }

    & + label:active .checkbox,
    &:checked + label:active .checkbox {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }

    &:checked + label .checkbox {
      background: rgb(216,109,49) url('/static/images/correct-symbol.svg') no-repeat center center;
      background-size: 70%;
      border-color: rgb(216,109,49);
    }
  }
}

.input-block {
  margin-bottom: 20px;

  .label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.form-field {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &.slug, &.gallery {
    display: none;
  }

  ul.errors {
    position: absolute;
    font-size: 10px;
    color: #E00;
    font-weight: bold;
    top: 0;
    right: 6px;
  }

  .label {
    display: block;
    font-weight: bold;
    margin-bottom: 0;
    flex: 0 0 132px;
    font-size: 12px;
    position: relative;

    &:after {
      content: ':';
    }

    &.required {
      &:before {
        position: absolute;
        left: -7px;
        top: -5px;
        content: '*';
        color: #E00;
      }
    }
  }
}
