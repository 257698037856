
.root-page {
  a:not(.button) {
    color: rgb(40,191,240);

    svg path.move {
      stroke: rgb(40,191,240);
      transition: stroke 0.35s ease;
    }

    &:hover {
      color: rgb(35,173,216);

      svg path.move {
        stroke: rgb(35,173,216);
      }
    }
  }

  .block-menu {
    background: linear-gradient(to bottom, rgb(35,173,216) 0%, rgb(40,191,240) 100%);
  }

  .container {
    position: relative;
  }

  .flash-messages-block {
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    position: absolute;
    width: 500px;
    color: #FFF;
    font-weight: bold;
    right: 0;
    transition: transform 0.35s ease, opacity 0.35s ease;
    transform: translateY(-50px);
    opacity: 0;

    &.visible {
      transform: translateY(0);
      opacity: 2;
    }

    .message {
      background: rgba(35,173,216,0.8);
      padding: 0 30px;
      height: 50px;
      display: flex;
      align-items: center;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea, select {
    &:focus, &:active {
      box-shadow: 0 0 0 5px rgba(35,173,216, 0.5);
    }
  }

  .save-line {
    position: sticky;
    bottom: 0;
    padding: 15px 0;
    border-top: 1px solid rgba(35,173,216,0.8);
    background: rgba(255,255,255,0.8);
  }

  .meta-urls {
    .item {
      margin-bottom: 16px;
      background: rgba(0,0,0,0.02);
      overflow: hidden;
      max-height: 30px;
      transition: background 0.35s ease, max-height 0.35s ease;

      &:hover {
        background: rgba(35,173,216,0.12);
      }

      &.open {
        max-height: 230px;
      }

      .head {
        cursor: pointer;
        display: flex;
        user-select: none;
        justify-content: space-between;
        background: rgba(35,173,216,0.04);

        .url {
          flex: 0 0 auto;
          padding: 5px 12px;
          background: rgba(35,173,216,0.08);
          font-family: monospace;
        }
        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #777;
          font-weight: bold;
          flex: 1 1 99%;
          padding: 5px 12px;
          text-align: right;
        }
      }

      .data {
        padding: 8px;

        input {
          margin-bottom: 8px;
        }

        label {
          color: #999;
          font-size: 12px;
          font-weight: bold;
        }

        .separated {
          display: flex;
          justify-content: space-between;

          .col {
            flex: 0 0 49.4%;
            width: 49.4%;

            textarea {
              resize: none;
              min-height: 45px;
            }
          }
        }
      }

    }
  }
}

