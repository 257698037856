.product-cart {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  margin-bottom: 26px;
  background: #FFFFFF;
  position: relative;

  .image-cover {
    background: #eee no-repeat center center;
    background-size: cover;
    display: block;
    height: 0;
    padding-bottom: 70%;
  }

  .status-badge {
    padding: 2px 8px;
    background: #f07937;
    opacity: 0.8;
    color: #FFF;
    position: absolute;
    right: 0px;
    top: 0px;

    &.code-2 {
      background: #F00;
    }
  }

  .title {
    display: block;
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    background: rgb(60,60,60);
    padding: 8px 5px 8px 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;

    @include respond-down(M){
      padding: 6px 3px 6px 12px;
    }
  }

  .params-list {
    padding: 8px 16px;

    @include respond-down(M){
      padding: 6px 12px;
    }

    .line {
      align-items: center;

      .value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .bottom {
    background: linear-gradient(to bottom, rgb(242,242,242) 0%, rgb(254,254,254) 100%);
    height: 36px;
    display: flex;
    
    .status {
      flex: 0 0 50%;
      font-weight: bold;
      color: rgb(249,22,29);
      align-items: center;
      justify-content: space-around;
      display: flex;
      text-transform: uppercase;

      &.mover {
        cursor: move;

        svg {
          width: 22px;

          path {
            fill: rgb(40,191,240);
          }
        }
      }

    }

    .button {
      flex: 0 0 50%;
    }
  }
}

.params-list {

  .line {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 8px 4px;
    font-size: 12px;

    @include respond-down(M){
      padding: 6px 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .name {
      // flex: 0 0 110px;
      padding-right: 8px;
      font-weight: bold;
    }

    /*
    &:first-child {
      flex-wrap: wrap;
      .name {
        flex: 0 0 100%;
      }
    }
    */
  }
}

.product-placeholder {
  background: #F00;
}
